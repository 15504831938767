import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Container, Box, Typography, Card, IconButton, InputBase, Paper, Fab,Grid } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import BottomNavigationComponent from '../others/BottomNavigationComponent';
import { useDispatch, useSelector } from 'react-redux';
import { PropertyContext } from '../../contexts/PropertyContext';
import colors from '../../styles/colors';

const RoomList = () => {
  const { properties, selectedProperty, loading, tenants, rooms, dues, complaints } = useSelector(state => state.property);
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredRooms = rooms.filter((room) =>
    room.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const totalRooms = rooms.length;
  const vacantRooms = rooms.filter(room => room.isAvailable).length;

  

  return (
    <Container sx={{pb: 18}}>
      <Box
        sx={{
          bgcolor: colors.secondary, // Primary color
          pb: 2,
          borderBottomLeftRadius: '8px',
          borderBottomRightRadius: '8px',
          boxShadow: '0px 1px 10px rgba(0, 0, 0, 0.1)',
          mx: -2,
          px: 3,
          position: 'sticky',
          top: 0,
          zIndex: 1000,
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', pt: 2, color: colors.primary }}>
          <Typography variant="h6" fontWeight={600}>Room List</Typography>
          <IconButton>
            <MoreVertIcon sx={{ color: colors.primary }} />
          </IconButton>
        </Box>
      </Box>

      
      <Grid container sx={{ mt: 0,mt:3,mb:1, ml: 2, color: colors.primary, fontWeight: 'medium', }}>
        <Grid item xs={4} >
          <Typography variant="body1">Total Rooms:</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="body1" sx={{ color: colors.primary, fontWeight: 'bold'}}>{totalRooms}</Typography>
        </Grid>
        <Grid item xs={4} >
          <Typography variant="body1">Vacant Rooms:</Typography>
        </Grid>
        <Grid item xs={2}>
        <Typography variant="body1" sx={{ color: colors.primary, fontWeight: 'bold'}}>{vacantRooms}</Typography>
        </Grid>     
      </Grid>

      <Paper sx={{ mt: 3, mb: 3, display: 'flex', alignItems: 'center', p: 1, borderRadius: '15px', border: '1px solid #ccc' ,boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.1)'}}>
        <SearchIcon sx={{ mr: 1, color: '#1AA393' }} />
        <InputBase
          placeholder="Search Rooms..."
          fullWidth
          value={searchTerm}
          onChange={handleSearchChange}
        />
      </Paper>

      {filteredRooms.map((room, index) => (
        <Card key={index} sx={{ mt: 2, p: 2, display: 'flex', alignItems: 'center',borderRadius: '10px', bgcolor: '#fff' , boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.1)'}}>
          <Box
                      component="img"
                      sx={{ height: 36, width: 36, pr:3,pl:1 }}
                      alt="Custom Home Icon"
                      src={`${process.env.PUBLIC_URL}/icons/blackrizzyicon.png`}
                    />
          <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <Typography variant="body1" noWrap sx={{ textTransform: 'capitalize',fontWeight: 600}}>
              <Link to={`/room-profile/${room._id}`} style={{ textDecoration: 'none', color: colors.primary }}>
                {room.name}
              </Link>
            </Typography>
            <Typography variant="body2">{room.floor}</Typography>
            
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', ml: 2 }}>
            <Typography variant="body2" color={colors.teal} sx={{ fontWeight: 'bold', mt: 1, textAlign: 'right' }}>
              Status: {room.isAvailable ? 'Vacant' : 'Occupied'}
            </Typography>
            <Typography variant="body2" color={colors.primary} sx={{ fontWeight: 'bold', mt: 1, textAlign: 'right' }}>
              Rent: ₹{room.rent}
            </Typography>
          </Box>
        </Card>
      ))}

      <Fab
        component={Link}
        to="/add-room"
        sx={{
          color: colors.gold,
          position: 'fixed',
          bottom: 80,
          right: 24,
          bgcolor: colors.primary, // Primary color
        }}
      >
        <AddIcon />
      </Fab>

      <BottomNavigationComponent />
    </Container>
  );
};

export default RoomList;
