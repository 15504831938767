import React, { useState,useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Container, Box, Typography, IconButton, Tabs, Tab, Paper, Card, InputBase, Button,Grid,
  CardContent, FormControl,Select, MenuItem,Menu } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Link, useLocation} from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import BottomNavigationComponent from '../others/BottomNavigationComponent';
import axios from 'axios';
import { PropertyContext } from '../../contexts/PropertyContext';
import { useDueContext } from '../../contexts/DueContext';
import Collections from './CollectionTab';
import { selectPaidDues, deleteDue, selectAggregatedPendingDues,selectTotalPendingDue,selectTotalCollection } from '../../features/propertySlice';
import colors from '../../styles/colors';



const expenses = [
  {
    category: 'Maintenance',
    amount: 5000,
    paidBy: 'John Doe',
  },
  {
    category: 'Utility Bills',
    amount: 3000,
    paidBy: 'Jane Doe',
  }, 
]; 
 
const RentDueCollection = () => {
  
  const navigate = useNavigate();
  const location = useLocation();
  const [searchTerm, setSearchTerm] = useState('');
  const { properties, selectedProperty, loading, tenants, rooms, dues, complaints } = useSelector(state => state.property);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedPeriod, setSelectedPeriod] = useState('currentMonth');

  const aggregatedPendingDues=useSelector(selectAggregatedPendingDues);
  const totalPendingDue = useSelector(selectTotalPendingDue);
  const totalCollection = useSelector(selectTotalCollection);

  const [tabIndex, setTabIndex] = useState(() => {
    const initialTab = location.state?.activeTab ?? 0;
    console.log('Setting initial tab index in rentdue:', initialTab);
    return initialTab;
  });

  useEffect(() => {
    console.log('RentDueCollection: location.state:', location.state);
    if (location.state?.activeTab !== undefined) {
      console.log('Updating tab index in RentDueCollection:', location.state.activeTab);
      setTabIndex(location.state.activeTab);
    }
  }, [location.state]);

  const getRoomName = (tenantId) => {
    const tenant = tenants.find((t) => t.tenant._id === tenantId);
    return tenant ? tenant.room.name : 'Unknown Room';
  };

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
    navigate('.', { state: { ...location.state, activeTab: newValue }, replace: true });
  };

  const handleRecordPayment = (pendingDues) => {
    navigate(`/record-payment/tenant/${pendingDues.tenant._id}`,  {
      state: {
        tenantName: pendingDues.name,
        dueAmount: pendingDues.dueAmount,
        dueType: pendingDues.dueType // or any other type
      }
    });
  };

  const handleAddDueClick = () => {
    navigate('/tenants');
  };

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const handlePeriodChange = (event) => {
    setSelectedPeriod(event.target.value);
  };

  const filteredDues = aggregatedPendingDues.filter((pendingDues) =>
    pendingDues.tenant && pendingDues.tenant.name && pendingDues.tenant.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const renderTabContent = () => {
    switch (tabIndex) {
      case 0:
        return (
          <Box >
            <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <Card sx={{ bgcolor: '#fff', borderRadius: 3 , boxShadow: '0 0px 0px rgba(0, 0, 0, 0.07)'}}>
            <CardContent>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <FormControl size="small" sx={{ minWidth: 120, ml: 2, mt: -2}}>
                      <Select
                        value={selectedPeriod}
                        onChange={handlePeriodChange}
                        displayEmpty
                        inputProps={{ 'aria-label': 'Select Period' }}
                        sx={{ height: 28,borderRadius: 50, fontWeight: 'medium' }}
                      >
                        <MenuItem value="currentMonth">{new Date().toLocaleString('default', { month: 'long' })}</MenuItem>
                        <MenuItem value="previousMonth">Previous Month</MenuItem>
                        <MenuItem value="last3Months">Last 3 Months</MenuItem>
                        <MenuItem value="currentYear">Current Year</MenuItem>
                      </Select>
                    </FormControl>
                  <Box
                    component="img"
                    sx={{ height: 70, width: 70, }}
                    alt="Money Bag"
                    src={`${process.env.PUBLIC_URL}/icons/Money-Bag-Rupeemoneybagrupee.png`}
                  />
                </Box>
                <Grid container sx={{ mt: 0, ml: 2, color: colors.primary, fontWeight: 'medium' }}>
                  
                <Grid item xs={5} sx={{ mt: 1}}>
                    <Typography align='left' paddingRight={2}>Rent Collected:</Typography>
                  </Grid>
                  <Grid item xs={5} sx={{ mt: 1}}>
                    <Typography sx={{fontSize: 18, color: colors.primary, fontWeight: 'bold'}}>₹{totalCollection}</Typography>
                  </Grid>
                  <Grid item xs={5} sx={{ mt: 1}}>
                    <Typography align='left' paddingRight={2}>Due Remaining:</Typography>
                  </Grid>
                  <Grid item xs={5} sx={{ mt: 1}}>
                    <Typography sx={{fontSize: 18, color: colors.primary, fontWeight: 'bold'}}>₹{totalPendingDue}</Typography>
                  </Grid>
                  <Grid item xs={5} sx={{ mt: 1}}>
                    <Typography align='left' paddingRight={2}>Total Expense:</Typography>
                  </Grid>
                  <Grid item xs={5} sx={{ mt: 1}}>
                    <Typography sx={{fontSize: 18 , color: colors.primary, fontWeight: 'bold'}}>₹90,000</Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          </Grid>
          </Box>
        );
      case 1:
        return (
          <Container maxWidth={false} disableGutters>
            <Box sx={{ width: '100%', mx: -2, my: -2, backgroundColor: '#f5f5f5', px: 2, py: 2, borderRadius: '10px' }}>
              <Box sx={{ mt: 0, mb: 3, display: 'flex', alignItems: 'center', p: 1, borderRadius: '4px', border: '1px solid #ccc', bgcolor: '#fff' }}>
                <SearchIcon sx={{ mr: 1, color: '#1AA393' }} />
                <InputBase 
                  placeholder="Search Tenants..."
                  fullWidth
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </Box>
              {filteredDues.map((pendingDues, index) => (
                <Card key={index} sx={{ mt: 2, p: 2, display: 'flex', alignItems: 'center', bgcolor: '#fff', position: 'relative', flexDirection: 'column', borderRadius: '10px', boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.1)' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: 40,
                      height: 40,
                      bgcolor: '#1AA393',
                      borderRadius: '50%',
                      color: '#fff',
                      fontSize: '0.875rem',
                      mr: 2,
                    }}
                  >
                    <Typography variant="h5" sx={{ fontSize: '1rem' }}>{pendingDues.tenant.name.charAt(0)}</Typography>
                  </Box>
                  <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                    <Typography variant="h6" sx={{ fontSize: '0.875rem' }}>
                    <Link
                      to={`/tenant-profile/${pendingDues.tenant._id}`}
                      state={{ activeTab: 1, previousTab: tabIndex }}
                      style={{ textDecoration: 'none', color: '#1AA393' }}
                      onClick={() => console.log('Navigating to TenantProfile with state:', { activeTab: 1, previousTab: tabIndex })}
                    >
                      {pendingDues.tenant.name}
                    </Link>
                    </Typography>
                    <Typography variant="body2" sx={{ fontSize: '0.75rem' }}>{getRoomName(pendingDues.tenant._id)}</Typography>
                  </Box>
                  <Box sx={{ textAlign: 'right' }}>
                    <Typography variant="body2" sx={{ fontWeight: 'bold', color: 'red', fontSize: '0.75rem' }}>
                    Total Due: ₹{pendingDues.totalPendingDue}
                    </Typography>
                    
                  </Box>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', mt: 2 }}>
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{ backgroundColor: '#1AA393', borderRadius: '50px', textTransform: 'none', flex: 1, marginRight: 0.5, fontSize: '0.75rem', color: 'white' }}
                    onClick={() => handleRecordPayment(pendingDues)}
                  >
                    Record Payment
                  </Button>
                  <Button variant="contained" color="primary" sx={{ backgroundColor: '#1AA393', borderRadius: '50px', textTransform: 'none', flex: 1, marginLeft: 0.5, fontSize: '0.75rem' }}>
                    <WhatsAppIcon sx={{ marginRight: 1, fontSize: '1rem' }} />
                    <Link to="/send-reminder" style={{ textDecoration: 'none', color: 'white', display: 'flex', alignItems: 'center' }}>
                      Remind To Pay
                    </Link>
                  </Button>
                </Box>
              </Card>
              ))}
              <Button
                variant="contained"
                color="primary"
                sx={{
                  color: 'black',
                  position: 'fixed',
                  bottom: 80,
                  right: 16,
                  backgroundColor: '#4DE5DD',
                  textTransform: 'none',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  minWidth: 120,
                }}
                onClick={handleAddDueClick}
              >
                <AddIcon sx={{ mr: 1 }} />
                Add Due
              </Button>
            </Box>
          </Container>
        );
      case 2:
        return <Collections />;
      case 3:
        return (
          <Box sx={{ width: '100%', mx: -2, my: -2, backgroundColor: '#f5f5f5', px: 2, py: 2, borderRadius: '10px' }}>
              <Box sx={{ mt: 0, mb: 3, display: 'flex', alignItems: 'center', p: 1, borderRadius: '4px', border: '1px solid #ccc', bgcolor: '#fff' }}>
                <SearchIcon sx={{ mr: 1, color: '#1AA393' }} />
                <InputBase
                  placeholder="Search Expense..."
                  fullWidth
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </Box>
            {expenses.map((expense, index) => (
              <Card key={index} sx={{ mt: 2, p: 2, display: 'flex', alignItems: 'center', bgcolor: '#fff', position: 'relative', flexDirection: 'column', borderRadius: '10px', boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.1)' }}>                <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: 40,
                      height: 40,
                      bgcolor: '#1AA393',
                      borderRadius: '50%',
                      color: '#fff',
                      fontSize: '0.875rem',
                      mr: 2,
                    }}
                  >
                    <Typography variant="h5" sx={{ fontSize: '1rem' }}>{expense.category.charAt(0)}</Typography>
                  </Box>
                  <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                    <Typography variant="h6" sx={{ fontSize: '0.875rem' }}>
                      {expense.category}
                    </Typography>
                  </Box>
                  <Box sx={{ textAlign: 'right' }}>
                    <Typography variant="body2" sx={{ fontWeight: 'bold', color: 'red', fontSize: '0.75rem' }}>
                      Amount: ₹{expense.amount}
                    </Typography>
                    <Typography variant="body2" sx={{ fontWeight: 'bold', color: '#1AA393', mt: 1, fontSize: '0.75rem' }}>
                      Paid by: {expense.paidBy}
                    </Typography>
                  </Box>
                </Box>
              </Card>
            ))}
            <Button
            variant="contained"
            color="primary"
            sx={{
              position: 'fixed',
              bottom: 80,
              right: 16,
              backgroundColor: '#1AA393',
              textTransform: 'none',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              minWidth: 120, // adjust width as needed
            }}
          >
            <AddIcon sx={{ mr: 1 }} />
            Add Expense
          </Button>
  
          </Box>
        );
      default:
        return null;
    }
  };

  return (
    <Container sx={{ pb: 18 }}>
      <Box
        sx={{
          bgcolor: colors.secondary, // Primary color
          pb: 2,
          borderBottomLeftRadius: '8px',
          borderBottomRightRadius: '8px',
          boxShadow: '0px 1px 10px rgba(0, 0, 0, 0.1)',
          mx: -2,
          px: 3,
          position: 'sticky',
          top: 0,
          zIndex: 1000,
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', pt: 2, color: colors.primary }}>
          <Typography variant="h6" fontWeight={600}>Money</Typography>
          <IconButton>
            <MoreVertIcon sx={{ color: colors.primary }} />
          </IconButton>
        </Box>
      </Box>

      <Tabs
        value={tabIndex}
        onChange={handleTabChange}
        centered
        sx={{
          '.MuiTab-root': {
            minWidth: 'auto',
            fontSize: '0.75rem',
          },
          '.MuiTabs-flexContainer': {
            flexWrap: 'nowrap',
            overflowX: 'auto',
          },
          '.MuiTabs-indicator': {
            backgroundColor: colors.darkteal, // Change the indicator color
          },
          '& .Mui-selected': {
            color: `${colors.darkteal} !important`, // Change the color of the selected tab text
            fontWeight:600
          },
          '& .MuiTab-root': {
            color: colors.primary, // Change the color of unselected tabs
            '&:hover': {
              color: colors.darkteal, // Change the hover color
              opacity: 1,
            },
          },
        }}
      >
        <Tab label="Dashboard" />
        <Tab label="Dues" />
        <Tab label="Collection" />
        <Tab label="Expenses" />
      </Tabs>

      <Paper sx={{ mt: 2, p: 2 ,mx:-2, boxShadow:0}}>
        {renderTabContent()}
      </Paper>

     

    <BottomNavigationComponent />

   </Container>
  );
};

export default RentDueCollection;
