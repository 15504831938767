import React, { useState, useEffect, useContext, useMemo } from 'react';
import { Container, Box, Typography, Card, IconButton, InputBase, Button, Paper, Fab, capitalize,Grid } from '@mui/material';
import CallIcon from '@mui/icons-material/Call';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SearchIcon from '@mui/icons-material/Search';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import { Link, useNavigate } from 'react-router-dom';
import BottomNavigationComponent from '../others/BottomNavigationComponent';
import { PropertyContext } from '../../contexts/PropertyContext';
import { Link as RouterLink } from 'react-router-dom';
import { useDueContext } from '../../contexts/DueContext';
import { selectPaidDues, selectPendingDues, selectAggregatedPendingDues } from '../../features/propertySlice';
import { useDispatch, useSelector } from 'react-redux';
import colors from '../../styles/colors';
import { Grid4x4Outlined } from '@mui/icons-material';



const TenantList = () => {
  const { properties, selectedProperty, loading, tenants, rooms, dues, complaints } = useSelector(state => state.property);
  const [searchTerm, setSearchTerm] = useState('');
  const aggregatedPendingDues = useSelector(selectAggregatedPendingDues);

  const navigate = useNavigate();

  console.log('it is coming to tenant list',tenants)


  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  

  const getTenantDueAmount = (tenantId) => {
    if (!tenantId) return 0;
    const tenantDues = aggregatedPendingDues.find(due => due.tenant && due.tenant._id === tenantId);
    return tenantDues ? tenantDues.totalPendingDue : 0;
  };

  const filteredTenants = useMemo(() => {
    console.log('Current tenants:', tenants);
    if (!searchTerm) return tenants; // Return all tenants if search term is empty
  
    return tenants.filter((tenantOccupancy) => {
      if (!tenantOccupancy || !tenantOccupancy.tenant || !tenantOccupancy.tenant.name) {
        console.log('Invalid tenantOccupancy:', tenantOccupancy);
        return false;
      }
      const includesSearchTerm = tenantOccupancy.tenant.name.toLowerCase().includes(searchTerm.toLowerCase());
      console.log(`Tenant ${tenantOccupancy.tenant.name} includes search term: ${includesSearchTerm}`);
      return includesSearchTerm;
    });
  }, [tenants, searchTerm]);

  const handleAddDueClick = (tenantId, roomId) => {
    console.log(`Adding due for tenant ID: ${tenantId}, room ID: ${roomId}`);
    navigate('/add-due', { state: { tenantId, roomId } });
  };

  const handleTenantCardClick = (tenantId, event) => {
    // Check if the click is on the card itself and not on a button or icon
    if (event.target === event.currentTarget || event.target.tagName === 'P') {
      navigate(`/tenant-profile/${tenantId}`);
    }
  };

  return (
    <Container sx={{ pb: 18}}> {/* Added padding-bottom */}
      <Box
        sx={{
          bgcolor: colors.secondary, // Primary color
          pb: 2,
          borderBottomLeftRadius: '8px',
          borderBottomRightRadius: '8px',
          boxShadow: '0px 1px 10px rgba(0, 0, 0, 0.1)',
          mx: -2,
          px: 3,
          position: 'sticky',
          top: 0,
          zIndex: 1000,
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', pt: 2, color: colors.primary }}>
          <Typography variant="h6" fontWeight={600}>Tenant List</Typography>
          <IconButton>
            <MoreVertIcon sx={{ color: colors.primary }} />
          </IconButton>
        </Box>
      </Box>

      
      <Grid container sx={{ mt: 0,mt:3,mb:1, ml: 2, color: colors.primary, fontWeight: 'medium', }}>
        <Grid item xs={4} >
          <Typography variant="body1">Total Tenant:</Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="body1" sx={{ color: colors.primary, fontWeight: 'bold'}}>{tenants.length}</Typography>
        </Grid>
        <Grid item xs={4} >
          <Typography variant="body1">Under Notice:</Typography>
        </Grid>
        <Grid item xs={2}>
        <Typography variant="body1" sx={{ color: colors.primary, fontWeight: 'bold'}}>5</Typography>
        </Grid>     
      </Grid>

      <Paper sx={{ mt: 2, mb: 3, display: 'flex', alignItems: 'center', p: 1, borderRadius: '15px', border: '1px solid #ccc',boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.1)' }}>
        <SearchIcon sx={{ mr: 1, color: colors.primary }} />
        <InputBase
          placeholder="Search Tenants..."
          fullWidth
          value={searchTerm}
          onChange={handleSearchChange}
        />
      </Paper>

      {filteredTenants.length > 0 ? (
        filteredTenants.map((tenantOccupancy) => {
          if (!tenantOccupancy || !tenantOccupancy.tenant) {
            console.log('Invalid tenantOccupancy in map:', tenantOccupancy);
            return null; // Skip this iteration if the data is invalid
          }

          const tenant = tenantOccupancy.tenant;
          const room = tenantOccupancy.room;
          const totalPendingDue = getTenantDueAmount(tenant._id);

          return (
            <Card key={tenantOccupancy._id} 
              sx={{ mt: 2, px: 2, py: 1, bgcolor: '#fff',
              borderRadius: '10px', boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.1)',
              cursor: 'pointer'
              }}
              onClick={(event) => handleTenantCardClick(tenant._id, event)}
            >
              <Grid container spacing={0} sx={{padding:1 }}>
                <Grid item xs={2.5} sx={{alignContent:'center'}}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: 50,
                      height: 50,
                      minWidth: 50,
                      minHeight: 50,
                      border:0,
                      borderColor:colors.primary,
                      bgcolor: colors.mint,
                      borderRadius: '50%',
                      color: colors.primary,
                      fontSize: '1.2rem',
                      mr: 0,
                    }}
                  >
                    <Typography variant="h6" sx={{ opacity: '50%',textTransform: 'capitalize' , fontWeight:'bold'}}>
                      {tenant.name ? tenant.name.charAt(0) : '?'}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={5.5} sx={{}}>
                  <Box>
                    <Typography variant="body1" noWrap sx={{ textTransform: 'capitalize',fontWeight: 600}}>
                      <RouterLink to={`/tenant-profile/${tenant._id}`} style={{ textDecoration: 'none', color: colors.primary }}>
                        {tenant.name || 'Unknown'}
                      </RouterLink>
                    </Typography>
                    <Typography variant="body2">{room.name}</Typography>
                    <Typography variant="body2">{room.floor}</Typography>
                  </Box>
                </Grid>
            <Grid item xs={4} sx={{textAlign:'right'}}>
            <Box >
              <Box sx={{ display: 'flex', gap: 1, mb: 0 ,justifyContent:'right', alignItems: 'center',}}>
                <IconButton aria-label="call" href={`tel:${tenant.phone}`} onClick={(e) => e.stopPropagation()} // Prevent card click when clicking the call button
                >
                  <CallIcon sx={{ color: colors.teal }} />
                </IconButton>
                <IconButton aria-label="whatsapp" href={`https://wa.me/${tenant.phone}`} onClick={(e) => e.stopPropagation()}>
                  <WhatsAppIcon sx={{ color: colors.teal }} />
                </IconButton>
              </Box>
              <Button
                variant="contained"
                sx={{
                  height: 28,
                  minWidth: 100,
                  borderRadius: 50,
                  color: colors.secondary,
                  fontWeight:550,
                  backgroundColor: colors.teal,
                  textTransform: 'none',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  borderColor:colors.teal,
                }}
                onClick={(e) => {
                  e.stopPropagation(); // Prevent card click when clicking the Add Due button
                  handleAddDueClick(tenant._id, room._id);
                }}
              >
                Add Due
              </Button>
              
            </Box>
            </Grid>
            </Grid>
            <Grid container sx={{padding:2, paddingBottom:1, paddingTop:1}}>
            <Grid item xs={8} sx={{textAlign:'left'}}>
            <Typography variant="body2" sx={{ fontWeight: 'bold', mt: 0 }}>
                Rent: ₹{tenantOccupancy.fixedRent}
              </Typography>
            </Grid>
            <Grid item xs={4} sx={{textAlign:'right'}}>
            {totalPendingDue > 0 ? (
                <Typography variant="body2" color="error" sx={{ fontWeight: 'bold', mt: 0 }}>
                  Due: ₹{totalPendingDue}
                </Typography>
              ) : (
                <Typography variant="body2" sx={{ fontWeight: 'bold', mt: 0 }}>
                  No Dues
                </Typography>
              )}
            </Grid>
            </Grid>
            </Card>
          );
        })
      ) : (
        <Typography variant="body1" sx={{ mt: 2, textAlign: 'center' }}>No tenants found</Typography>
      )}

      <Fab
        component={Link}
        to="/add-tenant"
        sx={{ position: 'fixed', bottom: 80, right: 24, bgcolor: colors.primary , color: colors.primary, border:1 ,boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.3)'}} // MUI default blue with padding-right
      >
        <PersonAddIcon sx={{color:colors.gold,}} />
        
      </Fab>

    
      <BottomNavigationComponent />

    </Container>
  );
};

export default TenantList;
