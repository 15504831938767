import React, { useState, useEffect, useContext, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BASE_URL } from '../../utils/IP'; 
import { Container, Box, Typography, Tabs, Tab, IconButton, Button, Paper, List, ListItem,
 ListItemText, Alert, CircularProgress,ToggleButton,ToggleButtonGroup,
 Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions,MenuItem,Menu,Avatar, Card, CardContent, Divider,Grid,Link } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import axios from 'axios';
import { deleteTenant,deleteDue } from '../../features/propertySlice';
import EditIcon from '@mui/icons-material/Edit';
import colors from '../../styles/colors';



const TenantProfile = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { tenantId } = useParams();
  const [error, setError] = useState(null);
  const [inviteSuccess, setInviteSuccess] = useState(false);

  const { tenants, rooms, dues, loading,selectedProperty } = useSelector(state => state.property);

  const [anchorEl, setAnchorEl] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogType, setDialogType] = useState('');
  const [dialogMessage, setDialogMessage] = useState('');
  const [selectedDue, setSelectedDue] = useState(null);

  const handleDeleteTenant = () => {
    setDialogType('deleteTenant');
    setDialogMessage('Are you sure you want to delete this tenant?');
    setDialogOpen(true);
  };

  const handleDeleteDue = (due) => {
    setSelectedDue(due);
    setDialogType('deleteDue');
    setDialogMessage('Are you sure you want to delete this due? This action cannot be undone.');
    setDialogOpen(true);
  };

  const handleDialogConfirm = async () => {
    setDialogOpen(false);
    try {
      if (dialogType === 'deleteTenant') {
        await dispatch(deleteTenant(tenantId)).unwrap();
        setDialogMessage('Tenant deleted successfully!');
        setDialogType('success');
        setDialogOpen(true);
        
      } else if (dialogType === 'deleteDue' && selectedDue) {
        console.log('Deleting due:', selectedDue); // Add this log
        if (!selectedDue._id) {
          throw new Error('Selected due does not have an ID');
        }
        await dispatch(deleteDue(selectedDue._id)).unwrap();
        setDialogMessage('Due deleted successfully');
        setDialogType('success');
        setDialogOpen(true);
        // Refresh the dues list here if needed
      }
    } catch (error) {
      console.error(`Error ${dialogType === 'deleteTenant' ? 'deleting tenant' : 'deleting due'}:`, error);
      setDialogMessage(`Failed to ${dialogType === 'deleteTenant' ? 'delete tenant' : 'delete due'}. ${error.message}`);
      setDialogType('error');
      setDialogOpen(true);
    }
  };

  const handleCloseDialog = () => {
    if(dialogMessage==='Tenant deleted successfully!' && dialogType==='success'){
      setDialogOpen(false);
      setSelectedDue(null);
      navigate('/tenants');
    }else 
    if(dialogMessage==='Due deleted successfully' && dialogType==='success'){
      setDialogOpen(false);
      setSelectedDue(null);
    }else
    {setDialogOpen(false);
      setSelectedDue(null);}
  };

  const handleOptionsClick = (event, due) => {
    event.stopPropagation(); // Prevent event bubbling
    setAnchorEl(event.currentTarget);
    setSelectedDue(due);
  };

  const handleOptionsClose = () => {
    setAnchorEl(null);
  };

  const handleUpdateDue = () => {
    handleOptionsClose();
    navigate(`/update-due/${selectedDue._id}`, { state: { dueData: selectedDue } });
    setDialogMessage('Due updated successfully');
    setDialogType('success');
    setDialogOpen(true);
  };

  const [tabIndex, setTabIndex] = useState(() => {
    return location.state?.activeTab ?? 0;
  });

  const tenant = useMemo(() => tenants.find(t => t.tenant._id === tenantId), [tenants, tenantId]);
  const room = useMemo(() => tenants.find(r => r.room._id === tenant?.room._id), [tenants, tenant]);
  const tenantDues = useMemo(() => dues.filter(d => d.tenant === tenantId || d.tenant?._id === tenantId), [dues, tenantId]);

  useEffect(() => {
    if (location.state?.activeTab !== undefined && location.state.activeTab !== tabIndex) {
      setTabIndex(location.state.activeTab);
    }
  }, [location.state, tabIndex]);


  const handleInviteTenant = async () => {
    setError(null);
    setInviteSuccess(false);

    if (!tenant) {
      setError('Tenant information not found. Cannot send invitation.');
      return;
    }
  
    const occupancyId = tenant._id;
  
    if (!occupancyId || !tenantId) {
      setError('Occupancy or Tenant information not found. Cannot send invitation.');
      return;
    }
  
    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(`${BASE_URL}/invite-tenant`, { // Remove the extra slash
        tenantId: tenantId,
        occupancyId: occupancyId,
      }, {
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': token,
        },
      });
  
      const inviteToken = response.data.token;
      const inviteLink = `http://tenant.rizzy.in/tapp-signup?token=${inviteToken}`;
  
      console.log('Invitation Link:', inviteLink);
      setInviteSuccess(true);
    } catch (error) {
      console.error('Error sending invitation:', error);
      setError('Failed to send invitation. Please try again.');
    }
  };

  const handleBackClick = () => {
    
    navigate('/tenants');
  };

  const handleTabChange = (event, newValue) => {
    console.log('Tab changed to:', newValue);
    setTabIndex(newValue);
    navigate('.', { 
      state: { ...location.state, activeTab: newValue, previousTab: location.state?.previousTab }, 
      replace: true 
    });
  };

  const handleRecordPayment = (dueId) => {
    navigate(`/record-payment2/${dueId}`);
  };

  // Toggle to switch between the paid dues & pending dues
  const [filter, setFilter] = useState('pending');

  const handleFilterChange = (event, newFilter) => {
    if (newFilter !== null) {
      setFilter(newFilter);
    }
  };

  const filteredDues = tenantDues.filter(due => {
    if (filter === 'paid') return due.status === 'Paid';
    return due.status === 'Pending';
  });
  
  


  const renderTabContent = () => {

    console.log('tenantData in rendering:', tenant);

      if (loading.tenants || loading.rooms || loading.dues) {
      return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
          <CircularProgress />
        </Box>
      );
    }

    if (!tenant) {
      return <Typography variant="h6">No tenant data available</Typography>;
    }


    switch (tabIndex) {
      case 0:
        return (
          <Box>
            <Box sx={{ p: 2, ml: 1 }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Profile Information</Typography>
              <Grid container spacing={2} sx={{ mt: 1, wordBreak: 'break-word' }}>
                <Grid item xs={5}>
                  <Typography variant="body2" color="textSecondary">Name</Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography variant="body1">{tenant.tenant.name}</Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography variant="body2" color="textSecondary">Room Number</Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography variant="body1">{tenant?.room.name || 'N/A'}</Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography variant="body2" color="textSecondary">Contact Number</Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography variant="body1">{tenant.tenant.phone}</Typography>
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ p: 2, ml: 1 }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Stay Details</Typography>
              <Grid container spacing={2} sx={{ mt: 1, wordBreak: 'break-word' }}>
                <Grid item xs={5}>
                  <Typography variant="body2" color="textSecondary">Move-in Date</Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography variant="body1">{new Date(tenant.moveIn).toLocaleDateString()}</Typography>
                </Grid>
                {tenant.moveOut && (
                  <>
                    <Grid item xs={5}>
                      <Typography variant="body2" color="textSecondary">Move-out Date</Typography>
                    </Grid>
                    <Grid item xs={7}>
                      <Typography variant="body1">{new Date(tenant.moveOut).toLocaleDateString()}</Typography>
                    </Grid>
                  </>
                )}
              </Grid>
            </Box>
            <Box sx={{ p: 2, ml: 1 }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Rent Details</Typography>
              <Grid container spacing={2} sx={{ mt: 1, wordBreak: 'break-word' }}>
                <Grid item xs={5}>
                  <Typography variant="body2" color="textSecondary">Fixed Rent</Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography variant="body1">{`₹${tenant.fixedRent}`}</Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography variant="body2" color="textSecondary">Security Deposit</Typography>
                </Grid>
                <Grid item xs={7}>
                  <Typography variant="body1">{`₹${tenant.securityDeposit}`} </Typography>
                </Grid>
              </Grid>
              <Link onClick={() => navigate(`/edit-tenant/${tenantId}`)} underline="hover" sx={{ display: 'flex', alignItems: 'center', mt: 2, color: '#03A0A2', textDecoration: 'underline' }}>
                <EditIcon sx={{ mr: 0.5, fontSize: 'inherit' }} />
                <Typography variant="body2">Edit details</Typography>
              </Link>
            </Box>
              <Box sx={{ mx: 3, display: 'flex', justifyContent: 'space-between', mt: 2, mb:8}}>
                <Button variant="contained" onClick={handleInviteTenant} 
                  sx={{ color:'#fff', bgcolor:'#1AA393', fontSize: 10, boxShadow: 0, borderRadius: 4 }}>
                  Invite Tenant
                </Button>
                <Button variant="contained" onClick={handleDeleteTenant} 
                  sx={{color:'#fff', bgcolor:'#DA6565', fontSize:10, boxShadow:0, borderRadius:4}}>
                  Delete Tenant
                </Button>
              </Box>
        </Box>
          
        );
        case 1:
          return (
            <Box>
             <Box sx={{ height:28,display: 'flex', justifyContent: 'center', mb: 2 , mt:2, }}>
              <ToggleButtonGroup
                value={filter}
                exclusive
                onChange={handleFilterChange}
                aria-label="dues filter"
                >
                <ToggleButton value="pending" aria-label="pending dues" 
                  sx={{
                    '&.Mui-selected': {
                      backgroundColor: '#000',
                      color: 'white',
                      '&:hover': {
                        backgroundColor: '#000',
                      },},
                      borderRadius:50
                    }}>
                  Pending
                </ToggleButton>
                <ToggleButton value="paid" aria-label="paid dues"
                  sx={{
                    '&.Mui-selected': {
                      backgroundColor: '#000',
                      color: 'white',
                      '&:hover': {
                        backgroundColor: '#000',
                      },},
                      borderRadius:50
                    }}>
                  Paid
                </ToggleButton>
              </ToggleButtonGroup>
             </Box>
              {filteredDues.length > 0 ? (
                [...filteredDues]
                .sort((a, b) => new Date(a.dueDate) - new Date(b.dueDate))
                .map((due, index) => (
                  <Paper key={index} sx={{ mt: 2, p: 2, alignItems: 'center', bgcolor: '#fff', borderRadius: '10px', boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.1)' }}>
                    <Typography variant="h7" sx={{ color: '#167D7F' }}>{due.dueType || 'Due'}</Typography>
                    {due.status === 'Pending' && (
                      <IconButton onClick={(event) => handleOptionsClick(event, due)} sx={{alignItems: 'right'}}>
                        <MoreVertIcon />
                      </IconButton>
                    )}
                    <Typography variant="body2">Amount: ₹{due.totalDue || due.dueAmount}</Typography>
                    <Typography variant="body2">Due Date: {new Date(due.dueDate).toLocaleDateString()}</Typography>
                    <Typography variant="body2">Status: {due.status}</Typography>
                    {due.status === 'Paid' && due.paymentDateTime && (
                      <Typography>Payment Date: {new Date(due.paymentDateTime).toLocaleDateString()}</Typography>
                    )}
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                      <Button variant="outlined" color="primary" sx={{ textTransform: 'none' }} onClick={() => handleRecordPayment(due._id)}>
                        Record Payment
                      </Button>
                      <Button variant="contained" color="secondary" sx={{ textTransform: 'none' }}>
                        Remind to Pay
                      </Button>
                      
                    </Box>
                  </Paper>
                ))
              ) : (
                <Typography variant="body1" sx={{ mt: 2 ,textAlign: 'center'}}>No Pending dues found for this tenant.</Typography>
              )}
            </Box>
          );
        default:
          return null;
      }
  };

  return (
    <Container sx={{bgcolor: colors.secondary , pb:6}}>
       {console.log('Rendering with tab index:', tabIndex)}
      <Box
        sx={{
          bgcolor: colors.secondary, // Primary color
          pb: 2,
          borderBottomLeftRadius: '8px',
          borderBottomRightRadius: '8px',
          boxShadow: '0px 1px 10px rgba(0, 0, 0, 0.1)',
          mx: -2,
          px: 2,
          color: colors.primary,
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', pt: 2 }}>
          <IconButton onClick={handleBackClick} sx={{ color: colors.primary }}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6" sx={{ fontSize: '1.2rem', textAlign: 'left', flexGrow: 1 }}>
            {tenant ? tenant.tenant.name : 'Tenant Profile'}
          </Typography>
          {tenant && (
            <Typography variant="h6" sx={{ fontSize: '1.2rem', textAlign: 'right' }}>
              {tenant.room.name}
            </Typography>
          )}
        </Box>
      </Box>

      <Tabs value={tabIndex} onChange={handleTabChange} centered>
        <Tab label="Tenant Details" />
        <Tab label="Passbook" />
      </Tabs>

      {renderTabContent()}

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleOptionsClose}
      >
        <MenuItem onClick={handleUpdateDue}>
          Update
        </MenuItem>
        <MenuItem onClick={() => {
          handleOptionsClose();
          handleDeleteDue(selectedDue);
        }}>
          Delete
        </MenuItem>
      </Menu>

      {error && (
        <Alert severity="error" onClose={() => setError(null)}>
          {error}
        </Alert>
      )}
      
      {inviteSuccess && (
        <Alert severity="success" onClose={() => setInviteSuccess(false)}>
          Invitation sent successfully!
        </Alert>
      )}

      <Dialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {dialogType === 'deleteTenant' || dialogType === 'deleteDue' ? "Confirm Action" : 
           dialogType === 'success' ? "Success" : "Error"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {(dialogType === 'deleteTenant' || dialogType === 'deleteDue') ? (
            <>
              <Button onClick={handleCloseDialog}>Cancel</Button>
              <Button onClick={handleDialogConfirm} autoFocus>
                Confirm
              </Button>
            </>
          ) : (
            <Button onClick={handleCloseDialog} autoFocus>
              OK
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default TenantProfile;