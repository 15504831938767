import React, { useState, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BASE_URL } from '../../utils/IP'; 
import { Container, Box, Typography, TextField, FormControl, InputLabel, Select, MenuItem, Button, IconButton, CircularProgress } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { PropertyContext } from '../../contexts/PropertyContext';
import axios from 'axios';
import { fetchTenants } from '../../features/propertySlice';
import colors from '../../styles/colors';
import {  styled } from '@mui/material';

// Custom styled Select component
const StyledSelect = styled(Select)(({ theme }) => ({
  '& .MuiSelect-select': {
    paddingTop: '16px',
    paddingBottom: '16px',
  },
}));



const AddTenant = () => {
  const [tenantData, setTenantData] = useState({
    name: '',
    phone: '',
    property: '',
    room: '',
    moveIn: null,
    fixedRent: '',
    securityDeposit: '',
    lockInPeriod: '',
    noticePeriod: '',
    autoRentAddition: '1st of month',
  });

  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { properties, selectedProperty, loading, tenants, rooms, dues, complaints } = useSelector(state => state.property);

  useEffect(() => {
    if (selectedProperty && !loading.rooms) {
      setTenantData(prevData => ({ ...prevData, property: selectedProperty._id }));
      setIsLoading(false);
    }
  }, [selectedProperty, loading.rooms]);

  const handleChange = (e) => {
    setTenantData({ ...tenantData, [e.target.name]: e.target.value });
  };

  const handleDateChange = (date, field) => {
    setTenantData((prevData) => ({
      ...prevData,
      [field]: date,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    const selectedRoom = rooms.find(room => room._id === tenantData.room);
    if (!selectedRoom) {
      setError('Please select a valid room');
      return;
    }

    if (selectedRoom.currentOcc >= selectedRoom.maxOcc) {
      setError('Selected room is already at maximum occupancy');
      return;
    }
    try {
      const token = localStorage.getItem('token');
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': token,
        },
      };
      // Convert moveIn and moveOut to ISO strings if they are not null
      const requestData = {
        ...tenantData,
        moveIn: tenantData.moveIn ? tenantData.moveIn.toISOString() : null,
        moveOut: tenantData.moveOut ? tenantData.moveOut.toISOString() : null,
      };
      console.log('Request body:', requestData); // For debugging
      const response = await axios.post(`${BASE_URL}/tenants`, requestData, config);
      console.log('Tenant added successfully:', response.data);

      if (tenantData.property) {
        console.log('Dispatching fetchTenants with property:', tenantData.property);
        const result = await dispatch(fetchTenants(tenantData.property));
        console.log('fetchTenants result:', result);
      }

      navigate('/tenants');
    } catch (error) {
      console.error('Error adding tenant:', error);
      setError('Error adding tenant: ' + (error.response?.data?.message || error.message));
  }
};

  const handleBackClick = () => {
    navigate(-1); // Go back to the previous screen
  };

  const availableRooms = rooms.filter(room => room.vacantOcc > 0);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
  }, []);

  if (isLoading) {
    return (
      <Container maxWidth="sm" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Container>
    );
  }


  return (
    <Container maxWidth="sm" disableGutters sx={{ pb: 8 }}>
      <Box
        sx={{
          bgcolor: colors.secondary, // Primary color
          pb: 2,
          borderBottomLeftRadius: '8px',
          borderBottomRightRadius: '8px',
          boxShadow: '0px 1px 10px rgba(0, 0, 0, 0.1)',
          mx: 0,
          px: 2,
          color: colors.primary,
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', pt: 2 }}>
          <IconButton onClick={handleBackClick} sx={{ color: colors.primary }}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6" sx={{ fontSize: '1.2rem', textAlign: 'left', flexGrow: 1 }}>
            Add Tenant
          </Typography>
        </Box>
      </Box>
      <form onSubmit={handleSubmit}>
        <Box sx={{ mb: 3, mt: 2, mx:1 , bgcolor: '#fff', p: 2, borderRadius: '8px' }}>
          <Typography variant="subtitle1" sx={{ color: '#1AA393', mb: 1 }}>Tenant Details</Typography>
          <TextField
            fullWidth
            label="Name"
            name="name"
            value={tenantData.name}
            onChange={handleChange}
            sx={{ mb: 2 }}
            required
          />
          <TextField
            fullWidth
            label="Phone"
            name="phone"
            value={tenantData.phone}
            onChange={handleChange}
            sx={{ mb: 2 }}
            required
          />
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel>Property</InputLabel>
            <Select
              name="property"
              value={tenantData.property}
              onChange={handleChange}
              label="Property"
              disabled // Disable changing property
              required
            >
              {properties.map((property) => (
                <MenuItem key={property._id} value={property._id}>
                  {property.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            select
            fullWidth
            label="Room"
            name="room"
            value={tenantData.room}
            onChange={handleChange}
            required
            sx={{ mb: 2 }}
          >
            {availableRooms.map((room) => (
              <MenuItem key={room._id} value={room._id}>
                {room.name} (Available: {room.vacantOcc})
              </MenuItem>
            ))}
          </TextField>
          <TextField
            fullWidth
            label="Referred By"
            name="referredBy"
            value={tenantData.referredBy}
            onChange={handleChange}
            sx={{ mb: 2 }}
          />
        </Box>

        <Box sx={{ mb: 3, mx:1, bgcolor: '#fff', p: 2, borderRadius: '8px' }}>
          <Typography variant="subtitle1" sx={{ color: '#1AA393', mb: 1 }}>Stay Details</Typography>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Move-in"
              value={tenantData.moveIn}
              onChange={(date) => handleDateChange(date, 'moveIn')}
              slotProps={{
                textField: {
                  fullWidth: true,
                  sx: { mb: 2 },
                  required: true,
                },
              }}
            />
            <DatePicker
              label="Move-out"
              value={tenantData.moveOut}
              onChange={(date) => handleDateChange(date, 'moveOut')}
              slotProps={{
                textField: {
                  fullWidth: true,
                  sx: { mb: 2 },
                },
              }}
            />
          </LocalizationProvider>
          <TextField
            fullWidth
            label="Lock-in Period"
            name="lockInPeriod"
            value={tenantData.lockInPeriod}
            onChange={handleChange}
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            label="Notice Period"
            name="noticePeriod"
            value={tenantData.noticePeriod}
            onChange={handleChange}
            sx={{ mb: 2 }}
          />
          <TextField
            select
            fullWidth
            label="Auto Rent Addition"
            name="autoRentAddition"
            value={tenantData.autoRentAddition}
            onChange={handleChange}
            required
            sx={{ mb: 2 }}
          >
            <MenuItem value="1st of month">1st of month</MenuItem>
            <MenuItem value="15th of month">15th of month</MenuItem>
          </TextField>
        </Box>

        <Box sx={{ mb: 3, mx: 1, bgcolor: '#fff', p: 2, borderRadius: '8px' }}>
          <Typography variant="subtitle1" sx={{ color: '#1AA393', mb: 1 }}>Payment Details</Typography>
          <TextField
            fullWidth
            label="Fixed Rent"
            name="fixedRent"
            value={tenantData.fixedRent}
            onChange={handleChange}
            sx={{ mb: 2 }}
            required
          />
          <TextField
            fullWidth
            label="Security Deposit"
            name="securityDeposit"
            value={tenantData.securityDeposit}
            onChange={handleChange}
            sx={{ mb: 2 }}
            required
          />
        </Box>

        <Button
          variant="contained"
          color="primary"
          type="submit"
          sx={{ mt: 2, mb: 2, justifyContent: 'center', alignItems: 'center' }}
        >
          Add Tenant
        </Button>
      </form>
    </Container>
  );
};

export default AddTenant;
