import React, { useState, useEffect,useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BASE_URL } from '../../utils/IP'; 

import { Box, Card, Typography, InputBase, Button } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { PropertyContext } from '../../contexts/PropertyContext';
import { useDueContext } from '../../contexts/DueContext';
import { selectPaidDues, selectPendingDues, selectAggregatedPendingDues } from '../../features/propertySlice';




const Collections = () => {
    // const [collections, setCollections] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const { properties, selectedProperty, loading, tenants, rooms, dues, complaints } = useSelector(state => state.property);

    const dispatch = useDispatch();


  
   /* useEffect(() => {
        const fetchCollections = async () => {
          if (!selectedProperty) return;
      
          const token = localStorage.getItem('token');
          const tenantIds = tenants.map(tenant => tenant._id).join(',');
          const config = {
            headers: {
              'Content-Type': 'application/json',
              'x-auth-token': token,
            },
          };
      
          try {
            const response = await axios.get(`${BASE_URL}/dues/fetch-paid-dues?tenantIds=${tenantIds}`, config);
            setCollections(response.data);
          } catch (error) {
            console.error('Error fetching collections:', error);
          }
        };
      
        fetchCollections();
      }, [selectedProperty, tenants]);  */


 const collections = useSelector(selectPaidDues);


  const filteredCollections = collections.filter((collection) =>
    collection.tenant && collection.tenant.name && collection.tenant.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  /* const filteredCollections = useMemo(() => {
    console.log('Current tenants:', collections);
    if (!searchTerm) return collections; // Return all tenants if search term is empty
  
    return collections.filter((collection) => {
      if (!collection || !collection.tenant || !collection.tenant.name) {
        console.log('Invalid tenantOccupancy:', collection);
        return false;
      }
      const includesSearchTerm = collection.tenant.name.toLowerCase().includes(searchTerm.toLowerCase());
      console.log(`Tenant ${collection.tenant.name} includes search term: ${includesSearchTerm}`);
      return includesSearchTerm;
    });
  }, [collections, searchTerm]); */

  return (
    <Box sx={{ width: '100%', mx: -2, my: -2, backgroundColor: '#f5f5f5', px: 2, py: 2, borderRadius: '10px' }}>
      <Box sx={{ mt: 0, mb: 3, display: 'flex', alignItems: 'center', p: 1, borderRadius: '4px', border: '1px solid #ccc', bgcolor: '#fff' }}>
        <SearchIcon sx={{ mr: 1, color: '#1AA393' }} />
        <InputBase
          placeholder="Search Tenants..."
          fullWidth
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </Box>
      {filteredCollections.map((collection, index) => (
        <Card key={index} sx={{ mt: 2, p: 2, display: 'flex', alignItems: 'center', bgcolor: '#fff', position: 'relative', flexDirection: 'column', borderRadius: '10px', boxShadow: '0px 1px 5px rgba(0, 0, 0, 0.1)' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                width: 40,
                height: 40,
                bgcolor: '#1AA393',
                borderRadius: '50%',
                color: '#fff',
                fontSize: '0.875rem',
                mr: 2,
              }}
            >
              <Typography variant="h5" sx={{ fontSize: '1rem' }}>{collection.tenant.name.charAt(0)}</Typography>
            </Box>
            <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
              <Typography variant="h6" sx={{ fontSize: '0.875rem' }}>
                <Link to={`/tenant-profile/${collection.tenant._id}`} style={{ textDecoration: 'none', color: '#1AA393' }}>
                  {collection.tenant.name}
                </Link>
              </Typography>
              <Typography variant="body2" sx={{ fontSize: '0.75rem' }}>{collection.room.name}</Typography>
            </Box>
            <Box sx={{ textAlign: 'right' }}>
              <Typography variant="body2" sx={{ fontWeight: 'bold', color: 'green', fontSize: '0.75rem' }}>
                Collected: ₹{collection.paymentAmount}
              </Typography>
              <Typography variant="body2" sx={{ fontWeight: 'bold', color: 'green', mt: 1, fontSize: '0.75rem' }}>
                Collected Date: {new Date(collection.paymentDateTime).toLocaleDateString()}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', mt: 2 }}>
            <Button variant="contained" color="primary" sx={{ borderRadius: '50px', backgroundColor: '#1AA393', textTransform: 'none', flex: 1, fontSize: '0.75rem' }}>
              <Link to="/share-receipt" style={{ textDecoration: 'none', color: 'white', display: 'flex', alignItems: 'center' }}>
                Share Receipt
              </Link>
            </Button>
          </Box>
        </Card>
      ))}
      <Button
        variant="contained"
        color="primary"
        sx={{
          position: 'fixed',
          bottom: 80,
          right: 16,
          backgroundColor: '#1AA393',
          textTransform: 'none',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          minWidth: 120, // adjust width as needed
        }}
      >
        <Link to="/record-payment" style={{ textDecoration: 'none', color: 'white', display: 'flex', alignItems: 'center' }}>
          <Typography>Record Payment</Typography>
        </Link>
      </Button>
    </Box>
  );
};

export default Collections;