import React, { useState, useEffect, useContext,useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BASE_URL } from '../../utils/IP'; 

import { Container, Box, Typography, IconButton, TextField, Button, Paper, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import axios from 'axios';
import { PropertyContext } from '../../contexts/PropertyContext';
import { fetchDues } from '../../features/propertySlice';
import colors from '../../styles/colors';




const RecordPayment2 = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { dueId } = useParams();
  const { properties, selectedProperty, loading, tenants, rooms, dues, complaints } = useSelector(state => state.property);


  const [dueDetails, setDueDetails] = useState(null);
  const [tenantName, setTenantName] = useState('');
  const [collectionMode, setCollectionMode] = useState('');
  const [description, setDescription] = useState('');
  const [uploadPhoto, setUploadPhoto] = useState(null);


  const selecteddue = useMemo(() => dues.find(d => d._id === dueId), [dues, dueId]);

  useEffect(() => {
    if (selecteddue) {
      setDueDetails(selecteddue);
    }
    setTenantName(selecteddue ? selecteddue.tenant.name : 'Unknown Tenant');
  }, [dues, selecteddue]);


  const handleCollectionModeChange = (event) => {
    setCollectionMode(event.target.value);
  };

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const handleUploadPhotoChange = (event) => {
    setUploadPhoto(event.target.files[0]);
  };

  const handleBackClick = () => {
    navigate(-1);
  };

  const handleSubmitPayment = async () => {
    const token = localStorage.getItem('token');
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': token,
      },
    };

    try {
      const response = await axios.post(
        `${BASE_URL}/dues/record-payment/due/${dueId}`,
        {
          amount: dueDetails.dueAmount,
          collectionMode,
          description,
          // Add logic to handle photo upload if needed
        },
        config
      );
      console.log('Payment response:', response.data);
      if (selectedProperty) {
        dispatch(fetchDues(selectedProperty._id));
      }
      navigate(-1);
    } catch (error) {
      console.error('Error recording payment:', error);
    }
  };

  if (!dueDetails) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <Container maxWidth="sm" disableGutters>
      <Box
        sx={{
          bgcolor: colors.secondary, // Primary color
          pb: 2,
          borderBottomLeftRadius: '8px',
          borderBottomRightRadius: '8px',
          boxShadow: '0px 1px 10px rgba(0, 0, 0, 0.1)',
          mx: 0,
          px: 2,
          color: colors.primary,
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', pt: 2 }}>
          <IconButton onClick={handleBackClick} sx={{ color: colors.primary }}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6" sx={{ fontSize: '1.2rem', textAlign: 'left', flexGrow: 1 }}>
            Record Payment for Specific Due
          </Typography>
        </Box>
      </Box>

      <Paper sx={{ mt: 2, p: 3, mx: 0 }}>
        <TextField
          fullWidth
          label="Tenant Name"
          variant="outlined"
          value={tenantName}
          disabled
          sx={{ mb: 2 }}
        />

        <TextField
          fullWidth
          label="Due Type"
          variant="outlined"
          value={dueDetails.dueType}
          disabled
          sx={{ mb: 2 }}
        />

        <TextField
          fullWidth
          label="Total Due Amount"
          variant="outlined"
          value={dueDetails.dueAmount}
          disabled
          sx={{ mb: 2 }}
        />

        <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
          <InputLabel id="collection-mode-label">Collection Mode</InputLabel>
          <Select
            labelId="collection-mode-label"
            value={collectionMode}
            onChange={handleCollectionModeChange}
            label="Collection Mode"
          >
            <MenuItem value="Cash">Cash</MenuItem>
            <MenuItem value="UPI">UPI</MenuItem>
          </Select>
        </FormControl>

        <TextField
          fullWidth
          label="Description"
          variant="outlined"
          value={description}
          onChange={handleDescriptionChange}
          sx={{ mb: 2 }}
        />

        <Button
          variant="contained"
          component="label"
          fullWidth
          sx={{ mb: 2, textTransform: 'none', bgcolor: '#4285F4', color: 'white' }}
        >
          Upload Photo
          <input
            type="file"
            hidden
            onChange={handleUploadPhotoChange}
          />
        </Button>

        <Button
          variant="contained"
          color="primary"
          fullWidth
          sx={{ backgroundColor: '#1AA393', textTransform: 'none' }}
          onClick={handleSubmitPayment}
        >
          Submit Payment
        </Button>
      </Paper>
    </Container>
  );
};

export default RecordPayment2;