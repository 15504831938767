import React, { createContext, useState, useContext, useEffect, useCallback, useMemo } from 'react';
import axios from 'axios';
import { PropertyContext } from './PropertyContext';
import { AuthContext } from '../contexts/AuthContext';
import { BASE_URL } from '../utils/IP'; 



const DueContext = createContext();

export const DueProvider = ({ children }) => {
  const { selectedProperty } = useContext(PropertyContext);
  const [dues, setDues] = useState([]);
  const [complaints, setComplaints] = useState([]);
  const [loading, setLoading] = useState(false);
  const { isAuthenticated, authChecked,isLoading } = useContext(AuthContext);


  const fetchDues = useCallback(async (dataType) => {
    if (!isAuthenticated || !selectedProperty) return;

    const propertyId = selectedProperty._id || selectedProperty.propertyId;
    console.log('fetch due main funtion , Selected Property:', propertyId);

    try {
      setLoading(true);
      const token = localStorage.getItem('token');
      const config = {
        headers: {
          'x-auth-token': token,
        },
      };

      const requests = [];
      if (dataType === 'dues' || dataType === 'all') {
        requests.push(axios.get(`${BASE_URL}/dues/property/${propertyId}`, config));
      }
      if (dataType === 'complaints' || dataType === 'all') {
        requests.push(axios.get(`${BASE_URL}/complaints/property/${propertyId}`, config));
      }

      const responses = await Promise.all(requests);

      if (dataType === 'dues' || dataType === 'all') {
        setDues(responses[0].data);
        console.log('dues:::3:::', responses[0].data);
      }
      if (dataType === 'complaints' || dataType === 'all') {
        setComplaints(responses[dataType === 'all' ? 1 : 0].data);
        console.log('complaint::3:::', responses[dataType === 'all' ? 1 : 0].data);
      }
    } catch (error) {
      console.error('Error fetching dues:', error);
    } finally {
      setLoading(false);
    }
  }, [isAuthenticated, selectedProperty]);

  useEffect(() => {
    console.log('useEffect of Fetchdues initialized');
    if (!isLoading && isAuthenticated && selectedProperty) {
      fetchDues('all');
    }
  }, [isLoading, isAuthenticated, selectedProperty, fetchDues]);

  const getPaidDues = useMemo(() => dues.filter(due => due.status === 'Paid'), [dues]);

  const getPendingDues = useMemo(() => dues.filter(due => due.status === 'Pending'), [dues]);

  const aggregatedPendingDues = useMemo(() => {
    const pendingDues = getPendingDues;
    const tenantDuesMap = new Map();

    pendingDues.forEach(due => {
      const tenantId = due.tenant._id;
      if (!tenantDuesMap.has(tenantId)) {
        tenantDuesMap.set(tenantId, {
          tenant: due.tenant,
          totalPendingDue: 0
        });
      }
      tenantDuesMap.get(tenantId).totalPendingDue += due.dueAmount;
    });

    return Array.from(tenantDuesMap.values())
      .sort((a, b) => b.totalPendingDue - a.totalPendingDue);
  }, [getPendingDues]);

  const contextValue = useMemo(() => ({
    dues,
    complaints,
    loading,
    getPaidDues,
    getPendingDues,
    aggregatedPendingDues,
    refreshDues: () => fetchDues('dues'),
    refreshComplaints: () => fetchDues('complaints'),
  }), [dues, complaints, loading, getPaidDues, getPendingDues, aggregatedPendingDues, fetchDues]);

  return (
    <DueContext.Provider value={contextValue}>
      {children}
    </DueContext.Provider>
  );
};

export const useDueContext = () => useContext(DueContext);