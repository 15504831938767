import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { BASE_URL } from '../utils/IP'; 
import axios from 'axios';
import WebSocketService from '../services/websocketService';


export const addProperty = createAsyncThunk(
  'property/addProperty',
  async (propertyData, { getState, dispatch, rejectWithValue }) => {
    const { auth } = getState();
    if (!auth.isAuthenticated) return rejectWithValue('Not authenticated');

    const token = localStorage.getItem('token');
    if (!token) return rejectWithValue('No token found');

    try {
      const response = await axios.post(`${BASE_URL}/properties`, propertyData, {
        headers: {
          'x-auth-token': token,
        },
      });

      WebSocketService.emit('PROPERTY_ADD', response.data);
      
      // Fetch updated properties list
      await dispatch(fetchProperties());
      
      // Set the new property as selected
      dispatch(setSelectedProperty(response.data));
      
      localStorage.setItem('selectedPropertyId', response.data._id);
      
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Handle logout if needed
        // You might want to dispatch a logout action here
      }
      return rejectWithValue(error.response?.data?.message || 'Error adding property');
    }
  }
);

export const refreshPropertyData = createAsyncThunk(
  'property/refreshPropertyData',
  async (propertyId, { dispatch, getState }) => {
    const { selectedProperty } = getState().property;
    if (selectedProperty && selectedProperty._id === propertyId) {
      await dispatch(fetchAllPropertyData(propertyId));
    }
  }
);

export const fetchAllPropertyData = createAsyncThunk(
  'property/fetchAllPropertyData',
  async (propertyId, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token');
      const [tenantsRes, roomsRes, duesRes, complaintsRes] = await Promise.all([
        axios.get(`${BASE_URL}/tenants/property/${propertyId}`, { headers: { 'x-auth-token': token } }),
        axios.get(`${BASE_URL}/rooms/${propertyId}`, { headers: { 'x-auth-token': token } }),
        axios.get(`${BASE_URL}/dues/property/${propertyId}`, { headers: { 'x-auth-token': token } }),
        axios.get(`${BASE_URL}/complaints/property/${propertyId}`, { headers: { 'x-auth-token': token } })
      ]);

      return {
        tenants: tenantsRes.data,
        rooms: roomsRes.data,
        dues: duesRes.data,
        complaints: complaintsRes.data
      };
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || 'Failed to fetch property data');
    }
  }
);

// Individual fetch actions
export const fetchProperties = createAsyncThunk(
  'property/fetchProperties',
  async (_, { getState, rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        return rejectWithValue('No token found');
      }

      const state = getState();
      const userId = state.auth.user?.id;
      if (!userId) {
        return rejectWithValue('User ID not found');
      }
      const response = await axios.get(`${BASE_URL}/properties/${userId}`, {
        headers: { 'x-auth-token': token },
      });
      return Array.isArray(response.data) ? response.data : [];
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchTenants = createAsyncThunk(
  'property/fetchTenants',
  async (propertyId, { rejectWithValue }) => {
    try {
      console.log('Fetching tenants for property:', propertyId);
      const token = localStorage.getItem('token');
      const response = await axios.get(`${BASE_URL}/tenants/property/${propertyId}`, {
        headers: { 'x-auth-token': token },
      });
      console.log('Fetched tenants:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error fetching tenants:', error);
      return rejectWithValue(error.message || 'Failed to fetch tenants');
    }
  }
);

export const fetchRooms = createAsyncThunk(
  'property/fetchRooms',
  async (propertyId, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${BASE_URL}/rooms/${propertyId}`, {
        headers: { 'x-auth-token': token },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchDues = createAsyncThunk(
  'property/fetchDues',
  async (propertyId, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${BASE_URL}/dues/property/${propertyId}`, {
        headers: { 'x-auth-token': token },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchComplaints = createAsyncThunk(
  'property/fetchComplaints',
  async (propertyId, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${BASE_URL}/complaints/property/${propertyId}`, {
        headers: { 'x-auth-token': token },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Add new thunks for adding comments and resolving complaints
export const addCommentToComplaint = createAsyncThunk(
  'property/addCommentToComplaint',
  async ({ complaintId, comment }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(`${BASE_URL}/complaints/${complaintId}/comment`, 
        { comment }, 
        { headers: { 'x-auth-token': token } }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const resolveComplaint = createAsyncThunk(
  'property/resolveComplaint',
  async ({ complaintId, resolution }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.put(`${BASE_URL}/complaints/${complaintId}/resolve`, 
        { resolution }, 
        { headers: { 'x-auth-token': token } }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteTenant = createAsyncThunk(
  'property/deleteTenant',
  async (tenantId, { dispatch, getState }) => {
    const token = localStorage.getItem('token');
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': token,
      },
    };
    await axios.delete(`${BASE_URL}/tenants/${tenantId}`, config);
    WebSocketService.emit('TENANT_DELETE', { tenantId });
    const { selectedProperty } = getState().property;
    if (selectedProperty) {
      await dispatch(fetchTenants(selectedProperty._id));
    }
    return tenantId;
  }
);

export const updateDue = createAsyncThunk(
  'property/updateDue',
  async ({ dueId, dueData }, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token');
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': token,
        },
      };
      const response = await axios.put(`${BASE_URL}/dues/${dueId}`, dueData, config);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const deleteDue = createAsyncThunk(
  'property/deleteDue',
  async (dueId, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem('token');
      const config = {
        headers: {
          'x-auth-token': token,
        },
      };
      await axios.delete(`${BASE_URL}/dues/${dueId}`, config);
      return dueId;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


const propertySlice = createSlice({
  name: 'property',
  initialState: {
    properties: [],
    selectedProperty: null,
    tenants: [],
    rooms: [],
    dues: [],
    complaints: [],
    loading: {
      properties: false,
      tenants: false,
      rooms: false,
      dues: false,
      complaints: false,
      allData: false,
    },
    error: null,
    lastFetchedPropertyId: null,
  },
  reducers: {
    setSelectedProperty: (state, action) => {
      const newPropertyId = action.payload._id;
      if (state.selectedProperty?._id !== newPropertyId) {
        state.selectedProperty = action.payload;
        localStorage.setItem('selectedPropertyId', newPropertyId);
        state.lastFetchedPropertyId = null; // Reset this to ensure data is fetched for the new property
      }
    },
    clearProperties: (state) => {
      state.properties = [];
      state.selectedProperty = null;
      state.tenants = [];
      state.rooms = [];
      state.dues = [];
      state.complaints = [];
      state.lastFetchedPropertyId = null;
      localStorage.removeItem('selectedPropertyId');
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch Properties
      .addCase(fetchProperties.pending, (state) => {
        state.loading.properties = true;
        state.error = null;
      })
      .addCase(fetchProperties.fulfilled, (state, action) => {
        state.properties = Array.isArray(action.payload) ? action.payload : [];
        state.loading.properties = false;
        const storedPropertyId = localStorage.getItem('selectedPropertyId');
        const newSelectedProperty = action.payload.find((prop) => prop._id === storedPropertyId) || action.payload[0];
        
        if (!state.selectedProperty || state.selectedProperty._id !== newSelectedProperty._id) {
          state.selectedProperty = newSelectedProperty;
          state.lastFetchedPropertyId = null; // Reset this to ensure data is fetched for the new property
        }
      })
      .addCase(fetchProperties.rejected, (state, action) => {
        state.loading.properties = false;
        state.error = action.payload;
      })
      // Fetch Tenants
      .addCase(fetchTenants.pending, (state) => {
        state.loading.tenants = true;
        state.error = null;
      })
      .addCase(fetchTenants.fulfilled, (state, action) => {
        state.loading.tenants = false;
        state.tenants = action.payload.filter(tenant => tenant != null);
      })
      .addCase(fetchTenants.rejected, (state, action) => {
        state.loading.tenants = false;
        state.error = action.payload || 'Failed to fetch tenants';
      })
      // Fetch Rooms
      .addCase(fetchRooms.pending, (state) => {
        state.loading.rooms = true;
      })
      .addCase(fetchRooms.fulfilled, (state, action) => {
        state.rooms = action.payload;
        state.loading.rooms = false;
      })
      .addCase(fetchRooms.rejected, (state, action) => {
        state.loading.rooms = false;
        state.error = action.payload;
      })
      // Fetch Dues
      .addCase(fetchDues.pending, (state) => {
        state.loading.dues = true;
      })
      .addCase(fetchDues.fulfilled, (state, action) => {
        state.dues = action.payload;
        state.loading.dues = false;
      })
      .addCase(fetchDues.rejected, (state, action) => {
        state.loading.dues = false;
        state.error = action.payload;
      })
      // Fetch Complaints
      .addCase(fetchComplaints.pending, (state) => {
        state.loading.complaints = true;
      })
      .addCase(fetchComplaints.fulfilled, (state, action) => {
        state.complaints = action.payload;
        state.loading.complaints = false;
      })
      .addCase(fetchComplaints.rejected, (state, action) => {
        state.loading.complaints = false;
        state.error = action.payload;
      })
      // fetch all details 
      .addCase(fetchAllPropertyData.pending, (state) => {
        state.loading.allData = true;
        state.error = null;
      })
      .addCase(fetchAllPropertyData.fulfilled, (state, action) => {
        state.tenants = action.payload.tenants;
        state.rooms = action.payload.rooms;
        state.dues = action.payload.dues;
        state.complaints = action.payload.complaints;
        state.loading.allData = false;
        state.lastFetchedPropertyId = state.selectedProperty._id;
      })
      .addCase(fetchAllPropertyData.rejected, (state, action) => {
        state.loading.allData = false;
        state.error = action.payload;
      })
      // addinf a new property
      .addCase(addProperty.pending, (state) => {
        state.loading.addProperty = true;
      })
      .addCase(addProperty.fulfilled, (state, action) => {
        state.loading.addProperty = false;
        state.properties.push(action.payload);
        state.selectedProperty = action.payload;
      })
      .addCase(addProperty.rejected, (state, action) => {
        state.loading.addProperty = false;
        state.error = action.payload;
      })
      .addCase(deleteTenant.fulfilled, (state, action) => {
        state.tenants = state.tenants.filter(tenant => tenant && tenant._id !== action.payload);
      })
      //Update & Delete Due
      .addCase(updateDue.fulfilled, (state, action) => {
        const index = state.dues.findIndex(due => due._id === action.payload._id);
        if (index !== -1) {
          state.dues[index] = action.payload;
        }
      })
      .addCase(deleteDue.fulfilled, (state, action) => {
        state.dues = state.dues.filter(due => due._id !== action.payload);
      })
      //complaint comments & resolution
      .addCase(addCommentToComplaint.fulfilled, (state, action) => {
        const { complaintId, comment } = action.payload;
        const complaintIndex = state.complaints.findIndex(c => c._id === complaintId);
        if (complaintIndex !== -1) {
          if (!state.complaints[complaintIndex].comments) {
            state.complaints[complaintIndex].comments = [];
          }
          state.complaints[complaintIndex].comments.push(comment);
        }
      })
      .addCase(resolveComplaint.fulfilled, (state, action) => {
        const { complaintId, resolution } = action.payload;
        const complaintIndex = state.complaints.findIndex(c => c._id === complaintId);
        if (complaintIndex !== -1) {
          state.complaints[complaintIndex].status = 'Resolved';
          state.complaints[complaintIndex].resolution = resolution;
        }
      });
  },
});

//selectors 

const selectDues = state => state.property.dues;

export const selectPaidDues = createSelector(
  [selectDues],
  (dues) => dues
  .filter(due => due.status === 'Paid')
  .sort((a, b) => new Date(b.paymentDateTime) - new Date(a.paymentDateTime))

);

export const selectPendingDues = createSelector(
  [selectDues],
  (dues) => dues.filter(due => due.status === 'Pending')
);

export const selectTotalPendingDue = createSelector(
  [selectPendingDues],
  (pendingDues) => pendingDues.reduce((total, due) => total + (due.dueAmount || 0), 0)
);

export const selectTotalCollection = createSelector(
  [selectPaidDues],
  (paidDues) => paidDues.reduce((total, due) => total + (due.paymentAmount || 0), 0)
);

export const selectAggregatedPendingDues = createSelector(
  [selectPendingDues],
  (pendingDues) => {
    const tenantDuesMap = new Map();

    pendingDues.forEach(due => {
      if (due && due.tenant) {
      const tenantId = due.tenant._id;
      if (!tenantDuesMap.has(tenantId)) {
        tenantDuesMap.set(tenantId, {
          tenant: due.tenant,
          totalPendingDue: 0
        });
      }
      tenantDuesMap.get(tenantId).totalPendingDue += due.dueAmount;
      }
    });

    return Array.from(tenantDuesMap.values())
      .sort((a, b) => b.totalPendingDue - a.totalPendingDue);
  }
);

// New selector to check if property data needs refreshing
export const selectShouldFetchPropertyData = createSelector(
  [
    state => state.property.selectedProperty,
    state => state.property.lastFetchedPropertyId,
  ],
  (selectedProperty, lastFetchedPropertyId) => {
    return selectedProperty && selectedProperty._id !== lastFetchedPropertyId;
  }
);



export const { setSelectedProperty, clearProperties } = propertySlice.actions;
export default propertySlice.reducer;