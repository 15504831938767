import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import {
  Box,
  Typography,
  IconButton,
  CircularProgress,
  Paper,
  Chip,
  Divider,
  Grid,
  Button,
  TextField,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Dialog,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PersonIcon from '@mui/icons-material/Person';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import SendIcon from '@mui/icons-material/Send';
import ImageIcon from '@mui/icons-material/Image';
import { BASE_URL } from '../../utils/IP';
import CentralDialog from '../../utils/CentralDialog';

const ComplaintDetails = () => {
  const { complaintId } = useParams();
  const navigate = useNavigate();
  const { complaints, loading } = useSelector(state => state.property);
  const [comment, setComment] = useState('');
  const [commentMedia, setCommentMedia] = useState(null);
  const [resolution, setResolution] = useState('');
  const [resolutionMedia, setResolutionMedia] = useState(null);
  const [openImagePreview, setOpenImagePreview] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);
  const [dialogProps, setDialogProps] = useState({
    open: false,
    title: '',
    content: '',
    type: '',
    onConfirm: null
  });

  const complaint = complaints.find(c => c._id === complaintId);

  const handleBackClick = () => {
    navigate(-1);
  };

  const handleImageClick = (imageUrl) => {
    setPreviewImage(imageUrl);
    setOpenImagePreview(true);
  };

  const handleCommentSubmit = async () => {
    const formData = new FormData();
    formData.append('text', comment);
    formData.append('commenterType', 'homeowner'); // Ensure this line is present
    if (commentMedia) {
      formData.append('media', commentMedia);
    }

    try {
      await axios.post(`${BASE_URL}/complaints/${complaintId}/comment`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'x-auth-token': localStorage.getItem('token')
        }
      });
      setComment('');
      setCommentMedia(null);
      setDialogProps({
        open: true,
        title: 'Success',
        content: 'Comment added successfully',
        type: 'success'
      });
    } catch (error) {
      console.error('Error submitting comment:', error);
      setDialogProps({
        open: true,
        title: 'Error',
        content: 'Failed to add comment. Please try again.',
        type: 'error'
      });
    }
  };

  const handleResolveComplaint = async () => {
    const formData = new FormData();
    formData.append('text', resolution);
    if (resolutionMedia) {
      formData.append('media', resolutionMedia);
    }

    try {
      await axios.put(`${BASE_URL}/complaints/${complaintId}/resolve`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'x-auth-token': localStorage.getItem('token')
        }
      });
      setResolution('');
      setResolutionMedia(null);
      setDialogProps({
        open: true,
        title: 'Success',
        content: 'Complaint resolved successfully',
        type: 'success'
      });
    } catch (error) {
      console.error('Error resolving complaint:', error);
      setDialogProps({
        open: true,
        title: 'Error',
        content: 'Failed to resolve complaint. Please try again.',
        type: 'error'
      });
    }
  };

  const handleCommentConfirmation = () => {
    setDialogProps({
      open: true,
      title: 'Confirm Comment',
      content: 'Are you sure you want to submit this comment?',
      type: 'confirm',
      onConfirm: handleCommentSubmit
    });
  };

  const handleResolveConfirmation = () => {
    setDialogProps({
      open: true,
      title: 'Confirm Resolution',
      content: 'Are you sure you want to resolve this complaint?',
      type: 'confirm',
      onConfirm: handleResolveComplaint
    });
  };

  if (loading.complaints) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (!complaint) {
    return (
      <Box sx={{ p: 2 }}>
        <Typography>Complaint not found</Typography>
      </Box>
    );
  }

  const renderComment = (comment, index) => {
    const isHomeowner = comment.commenterType === 'homeowner';
    return (
      <ListItem 
        key={index} 
        alignItems="flex-start"
        sx={{
          flexDirection: isHomeowner ? 'row' : 'row-reverse',
          justifyContent: isHomeowner ? 'flex-start' : 'flex-end',
        }}
      >
        <Avatar sx={{ bgcolor: isHomeowner ? 'primary.main' : 'secondary.main', mr: isHomeowner ? 2 : 0, ml: isHomeowner ? 0 : 2 }}>
          {isHomeowner ? 'H' : 'T'}
        </Avatar>
        <Box
          sx={{
            maxWidth: '70%',
            backgroundColor: isHomeowner ? 'primary.light' : 'secondary.light',
            borderRadius: 2,
            p: 2,
            mb: 2
          }}
        >
          <Typography variant="body1">{comment.text}</Typography>
          <Typography variant="caption" color="text.secondary" sx={{ display: 'block', mt: 1 }}>
            {new Date(comment.date).toLocaleString()}
          </Typography>
          {comment.media && (
            <Box 
              component="img"
              src={`${BASE_URL}/${comment.media}`}
              alt={`Comment media ${index + 1}`}
              sx={{ 
                width: '100%',
                maxHeight: '200px',
                objectFit: 'cover',
                borderRadius: 1,
                mt: 1,
                cursor: 'pointer'
              }}
              onClick={() => handleImageClick(`${BASE_URL}/${comment.media}`)}
            />
          )}
        </Box>
      </ListItem>
    );
  };

  return (
    <Box sx={{ bgcolor: '#FFF', minHeight: '100vh', p: 0 }}>
      {/* Header */}
      <Box sx={{ display: 'flex', alignItems: 'center', p: 2, boxShadow: '0px 1px 10px rgba(0, 0, 0, 0.1)' }}>
        <IconButton edge="start" color="inherit" onClick={handleBackClick}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h6">Complaint Details</Typography>
      </Box>

      {/* Complaint Details */}
      <Paper elevation={0} sx={{ m: 2, p: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <PersonIcon sx={{ mr: 2, color: 'text.secondary' }} />
          <Typography variant="h6">
            {complaint.tenant?.name || 'Unknown Tenant'}
          </Typography>
        </Box>
        
        <Divider sx={{ my: 2 }} />

        <Typography variant="h5" gutterBottom>{complaint.category}</Typography>
        <Chip 
          label={complaint.status} 
          color={complaint.status === 'Resolved' ? 'success' : 'warning'} 
          sx={{ mb: 2 }}
        />
        <Typography variant="body2" color="text.secondary" paragraph>
          Reported on: {new Date(complaint.date).toLocaleDateString()}
        </Typography>
        
        <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 'bold', mt: 3 }}>
          Complaint Description:
        </Typography>
        <Typography variant="body1" paragraph sx={{ pl: 2 }}>
          {complaint.description}
        </Typography>

        {complaint.media && complaint.media.length > 0 && (
          <>
            <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 'bold', mt: 3 }}>
              Attached Media:
            </Typography>
            <Grid container spacing={2}>
              {complaint.media.map((mediaPath, index) => (
                <Grid item xs={4} key={index}>
                  <Box 
                    component="img"
                    src={`${BASE_URL}/${mediaPath}`}
                    alt={`Complaint media ${index + 1}`}
                    sx={{ 
                      width: '100%', 
                      height: '150px', 
                      objectFit: 'cover', 
                      borderRadius: 2,
                      cursor: 'pointer'
                    }}
                    onClick={() => handleImageClick(`${BASE_URL}/${mediaPath}`)}
                  />
                </Grid>
              ))}
            </Grid>
          </>
        )}

        {/* Comments Section */}
        <Divider sx={{ my: 2 }} />
        <Typography variant="subtitle1" gutterBottom sx={{ fontWeight: 'bold' }}>
          Comments:
        </Typography>
        <List>
          {complaint.comments && complaint.comments.map((comment, index) => (
            <ListItem 
              key={index} 
              alignItems="flex-start"
              sx={{
                flexDirection: comment.commenterType === 'homeowner' ? 'row' : 'row-reverse',
                justifyContent: comment.commenterType === 'homeowner' ? 'flex-start' : 'flex-end',
              }}
            >
              <ListItemAvatar>
                <Avatar><PersonIcon /></Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={comment.text}
                secondary={
                  <>
                    <Typography
                      component="span"
                      variant="body2"
                      color="text.primary"
                      sx={{ display: 'block', fontSize: '0.8rem', mt: 0.5 }}
                    >
                      {new Date(comment.date).toLocaleString()}
                    </Typography>
                    {comment.media && (
                      <Box 
                        component="img"
                        src={`${BASE_URL}/${comment.media}`}
                        alt={`Comment media ${index + 1}`}
                        sx={{ 
                          width: '100px', 
                          height: '100px', 
                          objectFit: 'cover', 
                          borderRadius: '8px',
                          mt: 1,
                          cursor: 'pointer'
                        }}
                        onClick={() => handleImageClick(`${BASE_URL}/${comment.media}`)}
                      />
                    )}
                  </>
                }
              />
            </ListItem>
          ))}
        </List>

        {/* Add Comment Form */}
        <Box component="form" onSubmit={(e) => { e.preventDefault(); handleCommentConfirmation(); }} sx={{ mt: 2 }}>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Add a comment"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            InputProps={{
              endAdornment: (
                <>
                  <input
                    accept="image/*"
                    style={{ display: 'none' }}
                    id="comment-media-file"
                    type="file"
                    onChange={(e) => setCommentMedia(e.target.files[0])}
                  />
                  <label htmlFor="comment-media-file">
                    <IconButton component="span">
                      <AttachFileIcon />
                    </IconButton>
                  </label>
                  <IconButton type="submit">
                    <SendIcon />
                  </IconButton>
                </>
              ),
            }}
          />
          {commentMedia && (
            <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
              <ImageIcon sx={{ mr: 1 }} />
              <Typography variant="body2">{commentMedia.name}</Typography>
            </Box>
          )}
        </Box>

        {complaint.status !== 'Resolved' && (
          <>
            <Divider sx={{ my: 3 }} />
            <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
              Resolve Complaint:
            </Typography>
            <Box component="form" onSubmit={(e) => { e.preventDefault(); handleResolveConfirmation(); }} sx={{ mt: 2 }}>
              <TextField
                fullWidth
                variant="outlined"
                placeholder="Resolution details"
                value={resolution}
                onChange={(e) => setResolution(e.target.value)}
                multiline
                rows={3}
                sx={{ mb: 2 }}
              />
              <input
                accept="image/*"
                style={{ display: 'none' }}
                id="resolution-media-file"
                type="file"
                onChange={(e) => setResolutionMedia(e.target.files[0])}
              />
              <label htmlFor="resolution-media-file">
                <Button variant="outlined" component="span" startIcon={<AttachFileIcon />}>
                  Attach Media
                </Button>
              </label>
              {resolutionMedia && (
                <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                  <ImageIcon sx={{ mr: 1 }} />
                  <Typography variant="body2">{resolutionMedia.name}</Typography>
                </Box>
              )}
              <Button
                variant="contained"
                color="primary"
                type="submit"
                sx={{ ml: 2 }}
              >
                Resolve Complaint
              </Button>
            </Box>
          </>
        )}

        {complaint.resolution && (
          <>
            <Divider sx={{ my: 3 }} />
            <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
              Resolution:
            </Typography>
            <Typography variant="body1" paragraph>
              {complaint.resolution.text}
            </Typography>
            <Typography variant="caption" color="text.secondary">
              Resolved on: {new Date(complaint.resolution.date).toLocaleString()}
            </Typography>
            {complaint.resolution.media && (
              <Box 
                component="img"
                src={`${BASE_URL}/${complaint.resolution.media}`}
                alt="Resolution media"
                sx={{ 
                  width: '100%',
                  maxHeight: '300px',
                  objectFit: 'cover',
                  borderRadius: 2,
                  mt: 2,
                  cursor: 'pointer'
                }}
                onClick={() => handleImageClick(`${BASE_URL}/${complaint.resolution.media}`)}
              />
            )}
          </>
        )}
      </Paper>

      {/* Image Preview Dialog */}
      <Dialog open={openImagePreview} onClose={() => setOpenImagePreview(false)} maxWidth="md">
        <img src={previewImage} alt="Full size preview" style={{ width: '100%', height: 'auto' }} />
      </Dialog>

      {/* Central Dialog for messages and confirmations */}
      <CentralDialog
        open={dialogProps.open}
        onClose={() => setDialogProps(prev => ({ ...prev, open: false }))}
        title={dialogProps.title}
        content={dialogProps.content}
        type={dialogProps.type}
        onConfirm={dialogProps.onConfirm}
      />
    </Box>
  );
};

export default ComplaintDetails;